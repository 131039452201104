@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap");
:root {
  --button-default-color: #fff;
  --button-color-secondary: #000;
  --sport-icon-color: #fb9b24;
  --brand-color-primary: #fb9b24;
  --brand-color-secondary: #fb9b24;
  --background-color-primary: #393939;
  --background-color-secondary: #616161;
  --background-color-lighten: #6a6a6a;
  --background-color-tertiary: #000;
  --background-color-lighter: #aaa;
  --background-color-darken: #242424;
  --font-color-primary: #e1e1e1;
  --font-color-secondary: #000;
  --font-color-tertiary: #999;
  --font-color-quaternary: #333;
  --highlight-color-primary: #ffcc00;
  --theme-color-danger: #c10000;
  --panel-color-flash: #f1f1f1;
  --free-bet-item__amount__color: var(--brand-color-primary);
  --free-bet-item__footer__background-color: #222;
  --round-robins__toggler__border-radius: 0px;
  --round-robins__toggler__flex-direction: row;
  --round-robins__toggler__justify-content: space-between;
  --round-robins__toggler__width: 100%;
  --round-robins__toggler__i__margin-right: 0px;
  --round-robins__toggler__border: none;
  --round-robins__toggler__background-color: var(--brand-color-primary);
  --round-robins__toggler__color: var(--font-color-secondary);
  --round-robins-summarize__total-return__border-top--with-premiums: 1px solid var(--font-color-primary); }

body {
  color: #ddd;
  font-family: 'Source Sans Pro', sans-serif; }

.history .emphasis {
  background: #000;
  padding: 0 4px;
  min-width: 44px;
  display: inline-block;
  text-align: center;
  border-radius: 4px; }

.input-group-addon {
  background-color: #222; }

.content footer .footer {
  border-top: 1px solid var(--font-color-secondary); }

.content footer .footer p {
  color: #c7c7c7; }

.tab-content .bet-slip-dropdowns .text-right {
  color: #ccc; }

.table-wrapper > header {
  background-color: var(--background-color-primary);
  border: 1px solid var(--font-color-secondary); }

.table-wrapper .table-bordered th {
  background: grey; }

.table-striped > tbody:nth-child(1n) > tr td {
  background-color: #cecece;
  color: var(--font-color-secondary); }

.table-striped > tbody:nth-child(2n) > tr td {
  background-color: #b3b3b3;
  color: var(--font-color-secondary); }

.loading-live {
  background: #111; }

.fa.fa-lock.odds-locked {
  color: #bebebe; }

h3 flag {
  display: none; }

.teams .team-name.team-name2 {
  border: none; }

.btn {
  border-radius: 2px; }

.event-summary-details__score-table {
  border-collapse: separate; }

.event-summary-details__score tr td.event-summary-details__score-team-period {
  border-radius: 3px; }

/****************/
/*  Stat Score  */
/****************/
#event-stat-score-top .STATSCORE__Tracker {
  text-align: center;
  background: linear-gradient(#ffcc33 0px, #fb9b24 100%);
  height: 80px; }

/* Bet-slip animation */
.bet-list .panel,
.bet-list .panel .panel-heading,
.bet-list .panel .btn-primary {
  transition: border-color 0.4s ease-in, transform 0.4s ease-in, background-color 0.4s ease-in; }

.bet-list .panel.-bet-added {
  border-color: var(--panel-color-flash);
  transform: scale(1.0125);
  transition: border-color 0.4s ease-in, transform 0.4s ease-in; }

.bet-list .panel.-bet-added .panel-heading,
.bet-list .panel.-bet-added .btn-primary {
  background-color: var(--panel-color-flash);
  transition: background-color 0.4s ease-in; }
